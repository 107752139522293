














import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ThresholdsLevel from '@/models/tyre/ThresholdsLevel'

@Component
export default class Thresholds extends Vue {
  @Prop({ type: Array, default: () => [] })
  private treadDepths: Array<number>

  @Prop({ type: Object })
  private thresholds: ThresholdsLevel

  private getThresholdIconClass(mm: number): string {
    if (mm <= this.thresholds?.critical) {
      return 'fas fa-exclamation-circle mr-0 red--text'
    } else if (mm <= this.thresholds?.warning) {
      return 'fas fa-exclamation-triangle mr-0 orange--text'
    } else {
      return 'fas fa-check-circle mr-0 green--text'
    }
  }

  private getThresholdClass(mm: number): string {
    if (mm <= this.thresholds?.critical) {
      return 'critical-threshold'
    } else if (mm <= this.thresholds?.warning) {
      return 'warning-threshold'
    } else {
      return 'normal-threshold'
    }
  }
}
